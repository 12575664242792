

















import Vue from 'vue';
import _capitalize from 'lodash/capitalize';

export default Vue.extend({
  name: 'AppIcon',
  inheritAttrs: false,
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: '',
    },
    height: {
      type: [Number, String],
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconComponent() {
      if (!this.$slots.default) return;
      const [node] = this.$slots.default;
      if (!node || !node.text) return;
      const name = _capitalize(node.text.trim());
      return () =>
        import(
          /* webpackChunkName: "icon" */ `@/components/icons/Icon${name}.vue`
        );
    },
  },
});
