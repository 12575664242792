





























































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppLoader from '@/components/AppLoader.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppListExpense from '@/components/AppListExpense.vue';
import EXPENSES from '@/graphql/queries/Expenses.graphql';
import expenseStatusColor from '@/utils/expenseStatusColor';
import { EXPENSE_STATUSES } from '@/utils/constants';
import { Expense, ExpensesQueryVariables, ExpenseStatus } from '@/types/schema';

export default Vue.extend({
  name: 'AdminExpenses',
  components: {
    AppMain,
    AppLoader,
    AppEmpty,
    AppListExpense,
  },
  data() {
    return {
      selected: EXPENSE_STATUSES.PENDING as ExpenseStatus,
      expenses: [] as Expense[],
    };
  },
  computed: {
    hasExpenses(): boolean {
      return this.expenses.length > 0;
    },
    expenseStatuses(): ExpenseStatus[] {
      return Object.values(EXPENSE_STATUSES);
    },
  },
  methods: {
    expenseStatusColor,
    changeTab(event: Event) {
      const { expenseStatuses: statuses } = this;
      const current = statuses.findIndex((status) => status === this.selected);
      const max = statuses.length - 1;
      const prev = Math.max(0, current - 1);
      const next = Math.min(max, current + 1);
      if (event.type === 'swiperight') this.selected = statuses[prev];
      if (event.type === 'swipeleft') this.selected = statuses[next];
    },
  },
  apollo: {
    expenses: {
      query: EXPENSES,
      variables(): ExpensesQueryVariables {
        return {
          status: [this.selected],
        };
      },
      fetchPolicy: 'cache-and-network',
    },
  },
});
